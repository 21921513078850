<!-- Simple Event. All one v-card. Simple rsvp yes/no, with description on page. No time reservations. No expansion panel -->
<template>
    <v-col>
        <v-row row wrap>
            <v-col v-if="event.status == 'Draft'" cols="12">
                <div class="font-weight-bold text-center">ADMIN PREVIEW</div>
            </v-col>
            <v-col cols="12">
                <div class="headline">{{ event.name }}</div>
            </v-col>

            <v-col cols="12">
                <div class="font-weight-light text-xs-left mt-n5"> WHEN: {{shortDate(event.start)}} until {{shortDate(event.stop)}}</div>
            </v-col>
            <v-col cols="12">
                <div class="font-weight-light text-xs-left mt-n5"> WHERE: {{event.location}}</div>
            </v-col>
            <v-card-text>

                <div class="mt-0 mb-n2"><strong>Will you be attending?</strong></div>

                <v-radio-group
                v-model="attendees"
                column
                >
                <v-radio
                    color="success"
                    :value=1
                >
                    <template v-slot:label>
                        <div v-if="attendees==1"><strong class="success--text">YES (myself or proxy)</strong></div>
                        <div v-else>YES (myself or proxy)</div>
                    </template>
                </v-radio>
               
                <v-radio
                    color="success"
                    :value=2
                >
                    <template v-slot:label>
                        <div v-if="attendees==2"><strong class="success--text">YES (myself or proxy, plus guest)</strong></div>
                        <div v-else>YES (myself or proxy, plus guest)</div>
                    </template>
                </v-radio>

               <v-radio
                    label="I cannot make it"
                    color="error"
                    :value=0
                >
                    <template v-slot:label>
                        <div v-if="attendees==0"><strong class="error--text">NO</strong></div>
                        <div v-else>NO</div>
                    </template>
                    </v-radio>
                </v-radio-group>

                <div v-if="attendees!=null" class="mt-n1 mb-n1"><strong class="success--text">Thank you for your RSVP (# attendees = {{attendees}})</strong></div>

            </v-card-text>

<!--
            <v-col cols="11">
                <div class="font-weight-bold text-xs-left" :style="{ color : rsvpColor }"><v-icon>{{rsvpIcon}}</v-icon>{{rsvpStatus}}</div>
            </v-col>
-->
   
                
                <v-card-text > {{event.description}}</v-card-text>
   
        </v-row>
    </v-col>
</template>

<script>
import moment from 'moment-timezone'
export default {
    data () {
        return {
            attendees: null,
            loadedFromDatabase: false,
        }
    },
    props: {
        event: {type: Object, required: true},
        hideDateDetail: {type: Boolean, required: false},
        isDashboard: {type: Boolean, required: false}
    },
    created(){
        let userRsvp = this.$store.state.userInfo.rsvp
        if(userRsvp != undefined){
            if(userRsvp[this.event.id] != undefined){
                this.attendees = userRsvp[this.event.id].attendees
            }
        }
        this.loadedFromDatabase = true
    },
    watch: {
        attendees: async function (newVal, oldVal) {
            if(newVal == undefined || newVal == oldVal || !this.loadedFromDatabase) return
            if(oldVal == undefined) oldVal = 0
            //let delta = newVal - oldVal
            let userUpdate = {}
            userUpdate['rsvp'] = {}
            userUpdate['rsvp'][this.event.id] = {}
            userUpdate['rsvp'][this.event.id]['attendees'] = newVal
            await FIREBASE.db.collection('users').doc(this.$store.state.userInfo.uid).update(userUpdate)   
            console.log("RSVP updated for event " + this.event.id + " to " + newVal)         
        }
    },
    computed: {
        rsvpClosed () { return this.closed(this.event.start)},
        userEvent () { 
            let eventRef = "event-" + this.event.id
            let userEvent = this.$store.state.userInfo[eventRef]
            return userEvent
        },
        rsvpStatus () {
            if(this.userEvent)
                return "Reservation Booked"
            else
                return "Reservation Required"
        },
        rsvpIcon () {
            if(this.userEvent)
                return "check_box"
        },
        rsvpColor () {
            if(this.userEvent)
                return "green"
            else    
                return "red"
        }
    },
    methods: {
        closed (dateStr) {
            let diff = moment(dateStr, "YYYY-MM-DD @HH:mm").diff(moment(), "hours")
            return diff <= 0
        },
        shortDate (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").calendar()
        },
        iconColor (dateStr) {
            let diff = moment(dateStr, "YYYY-MM-DD @HH:mm").diff(moment(), "hours")
            if(diff < 24)
                return "red"
            else return ""
        },
    }
}
</script>
<style scoped>
.v-btn-toggle {
  flex-direction: column;
}
</style>
